import { Renter } from "@/api/renters/renters";
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { CreateRenterModal } from "./createRenterModal";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";

export function ChooseRenterDataSourceModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const [ogrn, setOgrn] = useState<string>("");
  const [renter, setRenter] = useState<Partial<Renter> | null>(null);
  const [renterLoading, setRenterLoading] = useState<boolean>(false);
  
  const handleError = useErrorHandling();

  const createRenterDisclosure = useDisclosure();

  useEffect(() => {
    if (!disclosure.isOpen) return;
    setOgrn("");
    setRenter(null);
    setRenterLoading(false);
  }, [disclosure.isOpen]);

  const skipCallback = useCallback(() => {
    disclosure.onClose();
    createRenterDisclosure.onOpen();
  }, [disclosure, createRenterDisclosure]);

  const findCallback = useCallback(() => {
    if (!ogrn.trim()) return; // Early exit for invalid OGRN
  
    setRenterLoading(true);
    api.dadata.getCompanyByOGRN(ogrn)
      .then(res => {
        setRenter({
          display_name: res.data[0]?.value || "",
          orgn: ogrn,
          email: res.data[0]?.data?.emails?.[0] || "",
          phone_number: res.data[0]?.data?.phones?.[0] || ""
        });
  
        disclosure.onClose();
        createRenterDisclosure.onOpen();
      })
      .catch(err => {
        setRenterLoading(false);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [ogrn, disclosure, createRenterDisclosure, handleError]);
   

  return (
    <>
      <CreateRenterModal disclosure={createRenterDisclosure} renter={renter} />
      <Modal
        isDismissable={false}
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.onOpenChange}
        portalContainer={document.getElementById("main")!}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(_onClose) => (
            <>
              <ModalHeader>Создание компании</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-4">
                  <span>Вы можете получить данные о компании автоматически, используя только ОГРН/ОГРНИП и нашу систему поиска.</span>
                  <Input
                    label="Введите ОГРН/ОГРНИП"
                    variant="bordered"
                    startContent={<FaMagnifyingGlass />}
                    value={ogrn}
                    onValueChange={setOgrn}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="w-full flex flex-row justify-between gap-2">
                  <Button className="w-full" color="default" onClick={skipCallback}>Введу данные вручную</Button>
                  <Button className="w-full" color="primary" isLoading={renterLoading} onClick={findCallback}>Продолжить</Button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}