interface ErrorDetail {
  loc: (string | number)[];
  msg: string;
}

interface ErrorResponse {
  detail?: ErrorDetail[];
}

export const parseErrorDetails = (error: ErrorResponse): { field: string; message: string }[] => {
  if (error?.detail && Array.isArray(error.detail)) {
    return error.detail.map((detail: ErrorDetail) => ({
      field: detail.loc.join('.').replace("body.", ""),
      message: detail.msg
    }));
  }
  return [];
};

export const generateErrorMessage = (error: ErrorResponse): string => {
  if (error?.detail && Array.isArray(error.detail)) {
    return error.detail.map((detail: ErrorDetail) => `${detail.loc.join('.')} - ${detail.msg}`).join('; ');
  }
  if (error?.detail && !Array.isArray(error.detail)) {
    return error.detail;
  }
  
  return 'Неизвестная ошибка';
};