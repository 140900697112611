import { Button, Link } from "@nextui-org/react";

export function NewAuthHomePage() {
  return (
    <>
      <div className="w-screen h-dvh min-h-dvh flex flex-col items-center justify-center">
        <div className="w-screen max-w-lg max-lg:max-w-screen-lg flex flex-col p-4">
          <div className="mb-2">
            <img src="/logo.svg" width={"128"}></img>
          </div>
          <div className="mb-2">
            <span className="font-medium text-xl">Кабинет арендатора</span>
          </div>
          <div className="mb-4">
            <span>Добро пожаловать!</span>
          </div>
          <div className="flex flex-col gap-2 justify-self-end">
            <Button
              as={Link}
              href="/auth/login"
              color="primary"
            >
              Войти
            </Button>
            <Button
              as={Link}
              href="/auth/register"
              color="primary"
              variant="flat"
            >
              Зарегистрироваться
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
