import { api } from "@/api";
import { Office } from "@/api/officies/officies.ts";
import { SharedLock } from "@/app/components/lock/sharedLock";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export function SharedLocksPage() {
  const [offices, setOffices] = useState<Office[]>([]);

  useEffect(() => {
    api.officies.list()
      .then(data => {
        setOffices(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке офисов!");
      });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-bold text-2xl">Общие замки</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены все общие замки, к которым у Вас есть доступ.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <div className="flex flex-row flex-wrap gap-2 max-w-[720px] p-2 mt-2">
            {offices.map((office) => (
              <SharedLock key={office.id} lock={{ id: office.id, displayName: office.display_name }} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}