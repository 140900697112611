import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaArrowRightToBracket, FaGears, FaPencil, FaPlus } from "react-icons/fa6";
import { Button, useDisclosure } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { api } from "@/api";
import { Renter } from "@/api/renters/renters.ts";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";

export function RentersPage() {
  const [renters, setRenters] = useState<Renter[]>([]);
  const navigate = useNavigate();

  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.renters.list()
      .then(data => {
        setRenters(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке компаний!");
      });
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Компании</span>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button
              startContent={<FaPlus />}
              color="primary"
              variant="solid"
              className="max-w-fit"
              onClick={() => navigate("/dashboard/renters/create")}
            >
              Добавить компанию
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            removeWrapper
            displayOptions={false}
            columns={[
              {
                label: "Название",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "ОРГН",
                key: "orgn",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Номер телефона",
                key: "phone_number",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Адрес почты",
                key: "email",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Владелец",
                key: "holder",
                type: ColumnType.User,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaArrowRightToBracket />,
                    onClick: (_value, row) => navigate(`/dashboard/renters/${row.id}`)
                  },
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => navigate(`/dashboard/renters/${row.id}/edit`)
                  },
                  {
                    icon: <FaGears />,
                    onClick: (_value, row) => navigate(`/dashboard/renters/${row.id}/roles`)
                  }
                ]
              }
            ]}
            data={renters || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </div>
  )
}
