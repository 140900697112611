import { useParams } from "react-router-dom";
import { TableBuilder } from "@/app/components/tables/tableBuilder.tsx";
import { useCallback, useEffect, useState } from "react";
import { api } from "@/api";
import { toast } from "react-toastify";
import { Office } from "@/api/officies/officies.ts";
import { FaLock, FaLockOpen } from "react-icons/fa6";
import { ColumnType } from "@/app/components/tables/tableTypes.ts";

export function LocationOfficesPage() {
  const { id } = useParams() as { id: string };
  const [offices, setOffices] = useState<Office[]>([]);

  useEffect(() => {
    api.officies.list()
      .then(data => {
        setOffices(data.data.filter(row => row.location === Number(id)));
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке офисов!");
      });
  }, []);

  const openLock = useCallback((id: number) => {
    api.locks.unlock(id)
      .then(data => {
        console.log(data);
        toast.success("Замок был успешно открыт!");
      })
      .catch(err => {
        console.log(err);
        toast.error("При открытии замка произошла ошибка!");
      });
  }, []);

  const closeLock = useCallback((id: number) => {
    api.locks.lock(id)
      .then(data => {
        console.log(data);
        toast.success("Замок был успешно закрыт!");
      })
      .catch(err => {
        console.log(err);
        toast.error("При закрытии замка произошла ошибка!");
      });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-col gap-2">
          <span className="font-bold text-2xl">Офисы</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены доступные офисы в выбранной ранее локации.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            columns={[
              {
                label: "Название",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              }, {
                label: "Тип офиса",
                key: "office_type",
                type: ColumnType.OfficeType,
                sortable: true
              }, {
                label: "Площадь",
                key: "area",
                type: ColumnType.Number,
                sortable: true
              }, {
                label: "Цена",
                key: "price",
                type: ColumnType.Number,
                sortable: true
              }, {
                label: "Кол-во комнат",
                key: "room_count",
                type: ColumnType.Number,
                sortable: true
              }, {
                label: "Локация",
                key: "location",
                type: ColumnType.Location,
                sortable: true
              }, {
                label: "Этаж",
                key: "floor",
                type: ColumnType.Number,
                sortable: true
              }, {
                key: "action",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaLockOpen />,
                    onClick: (_value, row) => { openLock(row.id) }
                  },
                  {
                    icon: <FaLock />,
                    onClick: (_value, row) => { closeLock(row.id) }
                  }
                ]
              }              
            ]}
            data={offices}
          />
        </div>
      </div>
    </>
  )
}