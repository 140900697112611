import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';
import { FaFile, FaHandPointer } from "react-icons/fa6";

interface FileUploadProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
}

const FileUpload = <TFieldValues extends FieldValues>({ control, name }: FileUploadProps<TFieldValues>) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], field: { onChange: (files: File[]) => void }) => {
      field.onChange(acceptedFiles);
    },
    []
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
          onDrop: (acceptedFiles) => onDrop(acceptedFiles, field),
          noClick: true,
        });

        return (
          <div className='w-full'>
            <div
              {...getRootProps()}
              className="select-none cursor-pointer flex flex-col gap-4 mt-2 border-2 border-dashed border-primary border-opacity-50 bg-background rounded-xl items-center px-4 py-8"
              onClick={open}
            >
              <input {...getInputProps()} />
              <div className="flex flex-col gap-4 items-center">
                <FaFile />
                <span className="text-sm">
                  {isDragActive ? "Отпустите файл здесь..." : "Перетащите файл"}
                </span>
              </div>
              <div className="flex flex-row gap-8 p-2 items-center w-full justify-center">
                <div className="block w-full border-solid border-b-1 border-default max-w-[100px]"></div>
                <span className="text-sm">или</span>
                <div className="block w-full border-solid border-b-1 border-default max-w-[100px]"></div>
              </div>
              <div className="flex flex-col gap-4 items-center">
                <FaHandPointer />
                <span className="text-sm">Нажмите, чтобы выбрать файл</span>
              </div>
            </div>

            {field.value && field.value.length > 0 && (
              <div className="mt-4">
                <h4 className="text-sm font-medium">Выбранные файлы:</h4>
                <ul className="list-disc list-inside text-sm">
                  {field.value.map((file: File, index: number) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default FileUpload;