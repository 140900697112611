export function PrivacyPolicyPage() {
  return (
    <>
      <div className="w-screen h-dvh min-h-dvh flex flex-col items-center justify-center">
        <div className="w-screen max-w-lg max-lg:max-w-screen-lg max-lg:min-h-dvh flex flex-col p-4">
          <div className="mb-2">
            <img src="/logo.svg" width={"128"}></img>
          </div>
          <div className="mb-4">
            <span className="font-medium text-xl">Политика Приватности</span>
          </div>
          <div className="mb-4">
            <span className="font-semibold">Дата вступления в силу: 31.12.2025</span>
          </div>
          <div className="mb-4">
            <span>Ваша конфиденциальность очень важна для нас. Мы собираем, используем и защищаем вашу личную информацию так, как описано в этой политике.</span>
          </div>
          <div className="mb-4">
            <span>Что мы собираем: Мы можем собирать ваш адрес электронной почты, имя и другие соответствующие данные, а так же использовать их с GPT, интегрированным в наши сервисы.</span>
          </div>
          <div className="mb-4">
            <span>Как мы используем данные: Ваши данные используются для улучшения качества обслуживания и предоставления функциональных возможностей. Они никогда не будут переданы третьим лицам.</span>
          </div>
          <div className="mb-4">
            <span>Контакты: Если у вас есть какие-либо вопросы, свяжитесь с нами по адресу iurii@chizhov.in.</span>
          </div>
        </div>
      </div>
    </>
  )
}
