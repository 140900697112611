import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDisclosure } from "@nextui-org/react";
import { api } from "@/api";
import { Billing } from "@/api/billings/billings.ts";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";

export function BillingsPage() {
  const [billings, setBillings] = useState<Billing[]>([]);

  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.billings.list()
      .then(data => {
        setBillings(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке платежей!");
      });
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Платежи</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены все платежи, связанные с Вами.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            removeWrapper
            displayOptions={false}
            columns={[
              {
                label: "Сумма",
                key: "amount",
                type: ColumnType.Number,
                sortable: true
              },
              {
                label: "Провайдер",
                key: "provider",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Статус",
                key: "paid",
                type: ColumnType.Boolean,
                sortable: true
              },
              {
                label: "Плательщик",
                key: "payer",
                type: ColumnType.Renter,
                sortable: true
              },
              {
                label: "Дата",
                key: "date",
                type: ColumnType.Date,
                sortable: true
              }
            ]}
            data={billings}
            rowsPerPage={10}
          />
        </div>
      </div>
    </div>
  )
}
