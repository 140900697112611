import { Button, Tab, Tabs, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaArrowRight, FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { api } from "@/api";
import { Ticket } from "@/api/tickets/tickets.ts";
import { CreateTicketModal } from "@/app/components/modals/tickets/createTicketModal";
import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { ColumnType } from "@/app/components/tables/tableTypes";

export function TicketsPage() {
  const [tickets, setTickets] = useState<Ticket[] | null>(null);
  const [rawTickets, setRawTickets] = useState<Ticket[] | null>(null);
  const [activeTab, setActiveTab] = useState<any>("open");
  const navigate = useNavigate();

  const createTicketModalDisclosure = useDisclosure();
  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.tickets.list()
      .then(response => {
        setRawTickets(response.data);
      })
      .catch(err => {
        toast.error("Произошла ошибка при загрузке тикетов!");
        console.log(err);
      })
  }, [
    createTicketModalDisclosure.isOpen
  ]);

  useEffect(() => {
    setTickets(rawTickets?.filter((ticket) => {
      if(activeTab === "all") return true;
      if(activeTab === "open") return ticket.status === "open";
      if(activeTab === "closed") return ticket.status === "closed";
    }) || []);
  }, [activeTab, rawTickets]);

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <CreateTicketModal disclosure={createTicketModalDisclosure} />
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Тикеты</span>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button
              startContent={<FaPlus />}
              color="primary"
              variant="solid"
              className="max-w-fit"
              onClick={() => createTicketModalDisclosure.onOpen()}
            >
              Новый тикет
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже размещены Ваши обращения к администрации Вашего коворкинга.</span>
        </div>
        <div className="flex flex-col gap-2 mb-4">
          <Tabs color="primary" variant="bordered" selectedKey={activeTab} onSelectionChange={setActiveTab}>
            <Tab key="all" title="Все обращения" />
            <Tab key="open" title="Открытые" />
            <Tab key="closed" title="Закрытые" />
          </Tabs>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            removeWrapper
            displayOptions={false}
            columns={[
              {
                key: "title",
                label: "Тема обращения",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "status",
                label: "Статус",
                type: ColumnType.Custom,
                sortable: true,
                render(value, _row) {
                  return (<span>{ value === "open" ? 'Открыт' : 'Закрыт' }</span>)
                },
              },
              {
                key: "category",
                label: "Категория",
                type: ColumnType.Category,
                sortable: true
              },
              {
                key: "office",
                label: "Офис",
                type: ColumnType.Office,
                sortable: true
              },
              {
                key: "created_at",
                label: "Создан",
                type: ColumnType.DateTime,
                sortable: true
              },
              {
                key: "updated_at",
                label: "Обновлён",
                type: ColumnType.DateTime,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaArrowRight />,
                    onClick: (_value, row) => { navigate(`/dashboard/tickets/${row.id}`) }
                  }
                ]
              }
            ]}
            data={tickets || []}
            rowsPerPage={10}
          />
        </div>
      </div>
    </div>
  )
}
