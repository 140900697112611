import rootStore from "@/app/store";
import { User } from "../auth/auth";
import crud, { ApiResponse } from "../crud";

interface CreateUserParams {
  username: string,
  password: string,
  first_name: string,
  middle_name: string,
  last_name: string,
  role: number,
  renter: number,
  phone_number: string,
}

export interface UserPermission {
  id: number;
  name: string;
  display_name: string;
}

export function UsersAPI(baseUrl: string) {
  return {
    list: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/users/?renter_id=${renterId}`, undefined, true) as ApiResponse<User[]>;
    },
    create: async (params: CreateUserParams) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/users/?renter_id=${renterId}`, params, true) as ApiResponse<User>;
    },
    get: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/users/${id}/?renter_id=${renterId}`, undefined, true) as ApiResponse<User>;
    },
    update: async (id: number, params: Partial<CreateUserParams>) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.put(`${baseUrl}/users/${id}/?renter_id=${renterId}`, params, true) as ApiResponse<User>;
    },
    delete: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.delete(`${baseUrl}/users/${id}/?renter_id=${renterId}`, true) as ApiResponse<boolean>;
    },
    generateInviteLink: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/users/generate-invite-link/?renter_id=${renterId}`, {}, true) as { result: string, invite_hash: string };
    },
    permissions: {
      list: async () => {
        const renterId = rootStore.getState().location.location.id;
        return await crud.get(`${baseUrl}/users/permissions?renter_id=${renterId}`, undefined, true) as ApiResponse<UserPermission[]>;
      },
      getUserPermissions: async (id: number) => {
        const renterId = rootStore.getState().location.location.id;
        return await crud.get(`${baseUrl}/users/${id}/permissions?renter_id=${renterId}`, undefined, true) as ApiResponse<UserPermission[]>;
      },
      updateUserPermissions: async (id: number, permissions: number[]) => {
        const renterId = rootStore.getState().location.location.id;
        return await crud.put(`${baseUrl}/users/${id}/permissions?renter_id=${renterId}`, { permissions }, true) as ApiResponse<UserPermission[]>;
      },
    }
  }
}