import crud, { ApiResponse } from "../crud";

function createFormUrlEncoded(data: any) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
}

export interface LoginParams {
  username: string;
  password: string;
  grant_type?: string;
  scope?: string;
  client_id?: string;
  client_secret?: string;
}

export interface LoginRequestResponse {
  access_token: string;
  token_type: string;
}

export interface User {
  id: number;
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  role: number;
  renter: number;
  phone_number: string;
  telegram: number;
  password?: string;
}

export interface RegisterUserParams {
  username: string,
  password: string,
  first_name: string,
  middle_name: string,
  last_name: string,
  phone_number: string
}

export enum ClientPermissions {
  ViewRentalInvoices = 'view_rental_invoices',
  ViewRentedProperties = 'view_rented_properties',
  AccessLandlordLocks = 'access_landlord_locks',
  AccessCommonLocks = 'access_common_locks',
  ManageLandlordProperties = 'manage_landlord_properties',
  ParticipateInSurveys = 'participate_in_surveys',
  ManageRenterEmployees = 'manage_renter_employees',
}

type PermissionsReponse = [
  {
    role: string,
    permissions: {
      [key: string]: string
    },
    renter: { id: number, display_name: string }
  }
];

export function AuthAPI(baseUrl: string) {
  return {
    login: async (params: LoginParams) => {
      const res = await crud.post(`${baseUrl}/auth/login`, createFormUrlEncoded(params), false, "application/x-www-form-urlencoded") as LoginRequestResponse;
      localStorage.setItem("access_token", res.access_token);
      return res;
    },
    validate: async () => {
      return await crud.post(`${baseUrl}/auth/validate`, {}, true) as string;
    },
    me: async () => {
      return await crud.get(`${baseUrl}/auth/me`, undefined, true) as ApiResponse<User>;
    },
    logout: async () => {
      await crud.post(`${baseUrl}/auth/logout`, {}, true) as string;
      localStorage.clear();
      location.href = "/";
    },
    generateTelegramLink: async() => {
      return await crud.get(`${baseUrl}/auth/generate-telegram-link`, undefined, true) as ApiResponse<{link: string}>;
    },
    getMyPermissions: async() => {
      return await crud.get(`${baseUrl}/auth/permissions`, undefined, true) as ApiResponse<PermissionsReponse>;
    },
    register: async (params: RegisterUserParams) => {
      return await crud.post(`${baseUrl}/auth/register`, params, false) as ApiResponse<User>;
    },
    connectRenter: async (hash: string) => {
      return await crud.post(`${baseUrl}/auth/connect-renter/${hash}`, undefined, true) as ApiResponse<{}>;
    },
    updateInfo: async (info: Partial<User>) => {
      return await crud.put(`${baseUrl}/auth/me`, info, true) as ApiResponse<{}>;
    },
    requestReset: async (data: { username: string }) => {
      return await crud.post(`${baseUrl}/auth/reset-password`, data, false) as ApiResponse<{}>;  
    }, 
    verifyReset: async (data: { username: string, code: number, new_password: string }) => {
      return await crud.post(`${baseUrl}/auth/reset-password-verify`, data, false) as ApiResponse<{}>;  
    }
  }
}