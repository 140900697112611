import { Column, ActionsColumn, ColumnType } from "../app/components/tables/tableTypes";

export function getNormalizedTime(date: string): string {
  return new Date(date).toLocaleString().slice(12, 17)
}

export function getNormalizedDate(date: string): string {
  return new Date(date).toLocaleString().slice(0, 10).replace(/\//g, ".")
}

export function getNormalizedDateTime(date: string): string {
  return new Date(date).toLocaleString().replace(/\//g, ".").slice(0, -3)
}

export function getPortal() {
  return document.getElementById("main")!
}

export function getDayName(day: string): string {
  switch(day) {
    case "monday": return "Понедельник";
    case "tuesday": return "Вторник";
    case "wednesday": return "Среда";
    case "thursday": return "Четверг";
    case "friday": return "Пятница";
    case "saturday": return "Суббота";
    case "sunday": return "Воскресенье";
    default: return "";
  }
}

export function isActionsColumn(column: Column): column is ActionsColumn {
  return column.type === ColumnType.Actions;
}

export function getLottieOptions(animationData: any) {
  return {
    loop: true,
    autoplay: true, 
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
}

export function hasAllPermissions(scopePermissions: string[], userPermissions: string[]) {
  return scopePermissions.every(permission => userPermissions.includes(permission));
}

export function getEnvironment(): "production" | "development" {
  return (window.location.hostname.indexOf("dev") !== -1 || window.location.hostname.indexOf("localhost") !== -1) ? "development" : "production";
}

export function getAPIPort(): number {
  return getEnvironment() == "development" ? 8200 : 8100;
}

export function getBaseUrl(): string {
  return getEnvironment() == "development" ? "https://dev.api.elasticspace.app" : "https://api.elasticspace.app";
}

export function sortAlphabeticallyByKey(array: any[], key: string) {
  return array.sort((a, b) => {
    if(a[key] < b[key]) return -1;
    if(a[key] > b[key]) return 1;
    return 0;
  });
}