import { Button, Input } from "@nextui-org/react";
import { FaPaperPlane } from "react-icons/fa6";
import { Chat, IMessage } from "@/app/components/chat/chat";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Ticket } from "@/api/tickets/tickets.ts";
import { api } from "@/api";
import { toast } from "react-toastify";

export function TicketPage() {
  const { id } = useParams() as { id: string };
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [messages, setMessages] = useState<IMessage[] | null>(null);

  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    api.tickets.get(Number(id))
      .then(response => {
        setTicket(response.data);
      })
      .catch(err => {
        toast.error("Произошла ошибка при загрузке тикета!");
        console.log(err);
      });

    api.tickets.messages.list(Number(id))
      .then(response => {
        setMessages(response.data.map(message => { return { id: message.id, date: message.created_at, isMe: message.sender == "user", message: message.message, user: message.sender } }));
      })
      .catch(err => {
        toast.error("Произошла ошибка при загрузке сообщений!");
        console.log(err);
      });
  }, [id]);

  const sendMessage = useCallback(() => {
    api.tickets.messages.create(ticket?.id || 0, { message: message, sender: String(ticket?.id), ticket_id: String(ticket?.id || 0) })
      .then(_result => { 
        api.tickets.messages.list(Number(id))
          .then(response => {
            setMessages(response.data.map(message => { return { id: message.id, date: message.created_at, isMe: message.sender == "user", message: message.message, user: message.sender } }));
          })
          .catch(err => {
            toast.error("Произошла ошибка при загрузке сообщений!");
            console.log(err);
          });
      })
      .catch(err => {
        toast.error("Произошла ошибка при отправке сообщения!");
        console.log(err);
      })
  }, [message, ticket]);

  return (
    <div className="flex flex-col gap-2 p-2 md:p-4 h-full overflow-hidden">
      <div className="flex flex-col flex-grow overflow-hidden">
        <Chat data={{ id: ticket?.id || 0, title: ticket?.title || "", adminView: false, messages: messages || [], ticket: ticket }} />
      </div>
      <div className="flex flex-row max-w-4xl gap-2 items-center">
        <div className="flex-grow">
          <Input variant="bordered" placeholder="Введите сообщение" value={message} onChange={(e) => { setMessage(e.target.value) }} />
        </div>
        <Button variant="flat" onClick={sendMessage}><FaPaperPlane /></Button>
      </div>
    </div>
  );
}