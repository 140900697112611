import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface Passcode {
  id: number;
  lock_id: number;
  passcode: string;
}

export interface PasscodeUpdate {
  passcode: string;
}

export interface LockLog {
  id: number;
  lock_id: number;
  user_id: number;
  CREATED_AT: string;
  message: string;
}

export type LockPasscode = Passcode;

export interface LockLogResponse {
  total_count: number;
  logs: LockLog[];
}

export function LocksAPI(baseUrl: string) {
  return {
    unlock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/unlock/?renter_id=${renterId}`, {}, true) as string;
    },
    lock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/lock/?renter_id=${renterId}`, {}, true) as string;
    },
    logs: async (id: number, limit: number = 10, offset: number = 0, time_from: string, time_to: string) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/locks/${id}/logs/?offset=${offset}&limit=${limit}&time_from=${time_from}&time_to=${time_to}&renter_id=${renterId}`, undefined, true) as ApiResponse<LockLogResponse>;
    },
    passcodes: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/locks/${id}/passcodes/?renter_id=${renterId}`, undefined, true) as ApiResponse<Passcode[]>;
    },
    updatePasscode: async (id: number, passcodeId: number, params: PasscodeUpdate) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/passcodes/${passcodeId}/?renter_id=${renterId}`, params, true) as string;
    },
    deletePasscode: async (id: number, passcodeId: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.delete(`${baseUrl}/locks/${id}/passcodes/${passcodeId}/?renter_id=${renterId}`, true) as string;
    }
  }
}