import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud";

export interface OfficeLocation {
  id: number;
  display_name: string;
  address: string;
  city: string;
  metro_station: string;
  district: string;
}

export function LocationsAPI(baseUrl: string) {
  return {
    list: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/locations/?renter_id=${renterId}`, undefined, true) as ApiResponse<OfficeLocation[]>;
    },
    get: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/locations/${id}/?renter_id=${renterId}`, undefined, true) as ApiResponse<OfficeLocation>;
    }
  }
}