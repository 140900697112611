import rootStore from "../app/store";

export async function getIdFromRenterHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.renter;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromUserRoleHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.userRole;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromCategoryHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.ticketCategory;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}

export async function getIdFromOfficeHandbook(displayName: string) {
  const data = rootStore.getState().handbooks.office;

  return (data.filter(el => el.display_name == displayName)[0]?.id || displayName);
}