import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { UserRole } from "../../store/handbooks/reducer";

export function NewUserRoleHandbook(props: AutocompleteProps<UserRole>) {
  const items = useAppSelector(state => state.handbooks.userRole);

  return (
    <Autocomplete
      defaultItems={items}
      label="Роль"
      placeholder="Выберите роль"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {`${item.id} - ${item.display_name}`}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function UserRoleHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.userRole);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Роль"
      placeholder="Выберите роль"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.id} - ${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}